import { Map, OrderedMap } from 'immutable';
import { actionsConfiguration } from './configuration';
import { PreviewType } from 'modules/stock/models/PreviewType';
import { configureActionMap } from 'reducerConfigurator/configureActionMap/configureActionMap';
import { getStateFromActionMap } from 'reducerConfigurator/configureReducer/getStateFromActionMap';

export const options = {
    singularName: 'previewType',
    pluralName: 'previewTypes',
    model: PreviewType,
};

export const namespaces = {
    itemsById: `${ options.pluralName }ById`,
    itemsCount: `${ options.pluralName }Count`,
    errors: 'errorsByFiled',

    fetchListLoader: `${ options.pluralName }ListIsLoading`,
    fetchLoader: `${ options.singularName }IsFetching`,
    editLoader: `${ options.singularName }isBeingEdited`,
    createLoader: `${ options.singularName }isBeingCreated`,
    removeLoader: `${ options.singularName }isBeingRemoved`,
    downloadFileLoader: `${ options.singularName }isBeingDownloaded`,
};

export const initialState = new Map({
    [namespaces.itemsById]: new OrderedMap({}),
    [namespaces.itemsCount]: 0,
    [namespaces.errors]: new Map({}),

    [namespaces.fetchListLoader]: false,
    [namespaces.fetchLoader]: false,
    [namespaces.editLoader]: false,
    [namespaces.createLoader]: false,
    [namespaces.removeLoader]: false,
});

const actionMap = configureActionMap({
    options,
    namespaces,
    initialState,
    actionsConfiguration,
});

export default function previewTypesReducer(state = initialState, action = {}) {
    return getStateFromActionMap({ state, action, actionMap });
}
