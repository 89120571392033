import { makeReducerSelector } from 'reducerConfigurator/configureSelectors/makeReducerSelector';
import { namespaces, options } from './reducer';
import { MODULE_NAME } from '../../constants';

const selector = makeReducerSelector(MODULE_NAME, options.pluralName);

export const selectPreviewTypes = selector(namespaces.itemsById);
export const selectPreviewTypesCount = selector(namespaces.itemsCount);
export const selectPreviewTypesErrors = selector(namespaces.errors);

export const selectPreviewTypesListLoader = selector(namespaces.fetchListLoader);
export const selectFetchPreviewTypeLoader = selector(namespaces.fetchLoader);
export const selectCreatePreviewTypeLoader = selector(namespaces.createLoader);
export const selectEditPreviewTypeLoader = selector(namespaces.editLoader);
