const toArray = (item) => {
    return typeof item === 'object' ?
        item
        :
        [item];
};

export const makeReducerSelector = (moduleName, reducer) => {
    return (rawPath) => {
        const path = toArray(rawPath);

        return (state, rawInDepthPath) => {
            const inDepthPath = toArray(rawInDepthPath);

            return rawInDepthPath ?
                state.getIn([moduleName, reducer, ...path, ...inDepthPath])
                :
                state.getIn([moduleName, reducer, ...path]);
        };
    };
};
