import { fork } from 'redux-saga/effects';
import { combineReducers } from 'redux-immutable';

import previewTypes from './previewTypes/reducer';
import previewTypesSaga from './previewTypes/saga';
import * as previewTypesActions from './previewTypes/actions';

export const stockReducers = combineReducers({
    previewTypes,
});

export const stockSagas = [
    fork(previewTypesSaga),
];

export const stockActions = [
    previewTypesActions,
];
