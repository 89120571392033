import get from 'lodash/get';
import {
    mapProductsFromApi,
    mapProductsCSVListToApi,
} from '../mappers/products';
import { paramsSerializer } from '../../utils/utils';
import {
    ApplicationJsonHeaders,
    FORMAT_YYYY_MM_DD_HH_mm_ss,
    HeadersAcceptType,
} from 'services/constants';

import i18next from 'i18next';
import { splitMultipleValueString } from '../utils/products';
import moment from 'moment';
import { IPagedResponse } from '../../../types/services.type';
import { omitNegative } from '../../../utils/filters';

class Products {
    constructor(productsService, services, URLS) {
        this.productsService = productsService;
        this.services = services;
        this.URLS = URLS;
        this.headers = ApplicationJsonHeaders;

        this.getById = this.getById.bind(this);
        this.getList = this.getList.bind(this);
        this.getCSVFile = this.getCSVFile.bind(this);
    }

    mapDateNumberToDate = (date: number | string | undefined) => {
        if (!date) {
            return '';
        }

        if (typeof date === 'number') {
            return new Date(parseInt(String(date)) * 1000).toLocaleString(); // Old way to map date, taken from saga
        }

        return new Date(date).toLocaleString();
    };

    getList({
        filters,
        per_page = 20,
        page = 1,
        sort = {},
    }): IPagedResponse<any> {
        const { ajax } = this.services;
        const { URLS } = this.productsService;
        const { headers } = this;

        const parsedFilters = omitNegative({
            id: filters?.productId,
            'pattern.code': splitMultipleValueString(filters?.patternCode),
            'intermediateProduct.id': filters?.intermediateProductId,
            'pattern.printSpec.id': filters?.patternPrintSpecId,
            'intermediateProduct.technology.id':
                filters?.intermediateProductTechnologyId,
            'offers.id': filters?.offerId,
            status: filters?.status,
            'createdAt[after]': this.mapDateNumberToDate(
                filters?.createdAt?.[0],
            ),
            'createdAt[before]': this.mapDateNumberToDate(
                filters?.createdAt?.[1],
            ),
        });

        const config = {
            params: {
                page,
                itemsPerPage: per_page,
                order: sort,
                ...parsedFilters,
            },
            paramsSerializer,
            headers,
        };

        return ajax
            .get({ url: URLS.GET_PRODUCTS, config })
            .then((response) => {
                const items = get(response, 'data.results', []);
                const itemsTotal = get(response, 'data.total', []);

                return {
                    items: items.map((product) => mapProductsFromApi(product)),
                    itemsTotal,
                };
            })
            .catch(() => ({
                items: [],
                itemsTotal: 0,
            }));
    }

    getById(id) {
        const { ajax } = this.services;

        const url = this.URLS.GET_PRODUCT.replace('{id}', id);
        const errorConfig = {
            throwError: this.productsService.errorHandlers.get,
            addGenericAlert: false,
        };

        const config = { headers: this.headers };

        return ajax
            .get({ url, config, errorConfig })
            .then((response) => mapProductsFromApi(response.data))
            .catch(() => ({}));
    }

    getCSVFile = async (filters = {}, fileName) => {
        const { ajax, alert } = this.services;
        const { URLS } = this.productsService;

        const {
            createdAt,
            patternCode,
            productId,
            status,
            intermediateProductId,
            patternPrintSpecId,
            intermediateProductTechnologyId,
            offerId,
        } = filters;

        const url = URLS.POST_PRODUCTS_LIST_CSV_FILE;
        const data = mapProductsCSVListToApi({
            ...(patternCode && patternCode !== ''
                ? {
                      patternCode: splitMultipleValueString(patternCode),
                  }
                : {}),
            ...(productId && productId !== ''
                ? {
                      productId: splitMultipleValueString(productId),
                  }
                : {}),
            ...(status && status !== ''
                ? {
                      status: splitMultipleValueString(status),
                  }
                : {}),
            ...(intermediateProductId && intermediateProductId !== ''
                ? { intermediateProductId: [] }
                : {}),
            ...(patternPrintSpecId && patternPrintSpecId !== ''
                ? { patternPrintSpecId: [] }
                : {}),
            ...(intermediateProductTechnologyId &&
            intermediateProductTechnologyId !== ''
                ? { intermediateProductTechnologyId: [] }
                : {}),
            ...(offerId && offerId !== '' ? { offerId: [] } : {}),
            ...(createdAt
                ? {
                      createdAt: {
                          ...(createdAt[1]
                              ? {
                                    after: moment(
                                        new Date(
                                            parseInt(String(createdAt[1])) *
                                                1000,
                                        ),
                                    ).format(FORMAT_YYYY_MM_DD_HH_mm_ss),
                                }
                              : {}),
                          ...(createdAt[0]
                              ? {
                                    before: moment(
                                        new Date(
                                            parseInt(String(createdAt[0])) *
                                                1000,
                                        ),
                                    ).format(FORMAT_YYYY_MM_DD_HH_mm_ss),
                                }
                              : {}),
                      },
                  }
                : {}),
        });

        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            throwError: this.productsService.errorHandlers.post,
            addGenericAlert: true,
        };

        await new Promise((resolve, reject) => {
            ajax.post({ url, data, config, errorConfig })
                .then((response) => {
                    const config = {
                        headers: {
                            Accept: HeadersAcceptType.csv,
                            'Content-Type': HeadersAcceptType.csv,
                        },
                    };

                    const id = response.data.filename;
                    const url = URLS.GET_PRODUCTS_LIST_CSV_FILE.replace(
                        '{id}',
                        id,
                    );

                    const errorConfig = {
                        addGenericAlert: false,
                    };

                    let timerId;

                    const getFile = async () => {
                        clearTimeout(timerId);

                        await ajax
                            .download({ url, config, fileName, errorConfig })
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                // Handling waiting for a CSV file
                                if (error.response?.status === 404) {
                                    timerId = setTimeout(() => {
                                        getFile();
                                    }, 2000);
                                } else {
                                    const errorStatus = `${error?.response?.statusText} (${error?.response?.status})`;
                                    const unknownError = i18next.t(
                                        'common:errors.unknown',
                                        { status: error?.response?.status },
                                    );

                                    const message = `${
                                        error.response.statusText
                                            ? errorStatus
                                            : unknownError
                                    }`;

                                    alert.addError({
                                        message,
                                    });

                                    reject(error);
                                }
                            });
                    };

                    getFile().catch((error) => {
                        reject(error);
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        }).catch(() => ({}));
    };
}

export default Products;
