import moment from 'moment';
import { filtersConfig } from 'modules/stock/components/patternsTableView/filters/filtersConfig';

const EQUAL_IN_FILTERS = ['dedicated_channel_id'];

const EQUAL_FILTERS = [
    'author_id',
    'technology_id',
    'category_id',
    'print_spec_id',
    'source_id',
    'external_producer_id',
    'color_definition',
];

const BOOLEAN_FILTERS = [
    'without_source',
    'without_source',
    'without_category',
    'without_color',
    'without_tag',
    'without_previews',
    'without_name',
    'with_color_version',
    'without_print_spec',
];

const MULTISELECT_SEPARATED_FILTERS = ['techniques'];

const MATCH_FILTERS = ['name', 'code', 'tag', 'color', 'overall_size', 'parts'];

const DATE_FILTERS = ['created_at_before', 'created_at_after'];

const INVERTED_BOOLEAN_FILTERS = ['is_not_imported'];

export const parsePatternFiltersToApi = (filters = {}) =>
    Object.entries(filters).reduce((acc, [key, value]) => {
        if (MULTISELECT_SEPARATED_FILTERS.includes(key)) {
            acc[key] = {
                $eq: value.join('|'),
            };
        }

        if (EQUAL_IN_FILTERS.includes(key)) {
            acc[key] = {
                $in: [value],
            };
        }

        if (EQUAL_FILTERS.includes(key)) {
            acc[key] = {
                $eq: value,
            };
        }

        if (MATCH_FILTERS.includes(key)) {
            acc[key] = {
                $match: value,
            };
        }

        if (INVERTED_BOOLEAN_FILTERS.includes(key)) {
            const { invertedSwitchKey } = filtersConfig[key];

            acc['$and'] = acc['$and'] ?? [];

            const apply = !!value;

            acc['$and'].push({
                [invertedSwitchKey]: {
                    $eq: !apply,
                },
            });
        }

        if (BOOLEAN_FILTERS.includes(key)) {
            acc['$and'] = acc['$and'] ?? [];

            acc['$and'].push({
                [key]: {
                    $eq: !!value,
                },
            });
        }

        if (DATE_FILTERS.includes(key)) {
            const timestamp = parseInt(
                moment(value).startOf('day').format('X'),
            );

            if (key.includes('_before')) {
                const parsedKey = key.replace('_before', '');

                acc[parsedKey] = {
                    ...acc[parsedKey],
                    $lte: timestamp,
                };
            }

            if (key.includes('_after')) {
                const parsedKey = key.replace('_after', '');

                acc[parsedKey] = {
                    ...acc[parsedKey],
                    $gte: timestamp,
                };
            }
        }

        return acc;
    }, {});
