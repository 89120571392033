export const getStatusFilterOptions = (t) => [
    {
        label: t('common:glossary.none'),
        value: '',
    },
    {
        label: t('arm:labels.productStatuses.active'),
        value: 'active',
    },
    {
        label: t('arm:labels.productStatuses.archived'),
        value: 'archived',
    },
];

export const getListingIdFromOrderLines = (orderLines) => {
    if (!orderLines || !orderLines.length) {
        return null;
    }

    const listingIds = orderLines.reduce((acc, { listingId }) => {
        return acc.includes(listingId) || !listingId
            ? acc
            : [...acc, listingId];
    }, []);

    return listingIds.join(', ');
};

export const splitMultipleValueString = (patternCode) =>
    patternCode?.split(/[ ,]+/);
