import { ApplicationJsonHeaders } from 'services/constants';
import { mapGetListV2Filters, mapSortToOrder } from '../mappers/previewTypes';
import { AsyncRequestPaginator } from 'services/utils/AsyncRequestPaginator';

import type { StockService } from '../StockService';
import type {
    IStockServiceConst,
    IStockServiceServices,
} from '../stockService.type';

class PreviewTypes {
    private readonly URLS: IStockServiceConst['URLS'];
    private readonly errorHandlers: StockService['errorHandlers'];

    private readonly asyncRequestPaginator: AsyncRequestPaginator<
        unknown,
        unknown
    >;

    constructor(
        private readonly stockService: StockService,
        private readonly services: IStockServiceServices,
    ) {
        this.URLS = this.stockService.URLS;
        this.errorHandlers = this.stockService.errorHandlers;

        this.getList = this.getList.bind(this);
        this.getById = this.getById.bind(this);
        this.delteById = this.delteById.bind(this);
        this.getMore = this.getMore.bind(this);
        this.getFullList = this.getFullList.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator(
            this.getMore,
            (data) => data,
        );
    }

    getMore({ filters = {}, sort = {}, per_page = 20, page = 1 } = {}) {
        const {
            services: { ajax },
            URLS,
            errorHandlers,
        } = this;

        const onError = errorHandlers.get;

        return ajax.get({
            url: URLS.GET_PREVIEW_TYPES,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...mapGetListV2Filters(filters),
                    page,
                    per_page,
                    sort,
                },
            },
            onError,
        });
    }

    async getFullList(params = {}) {
        const { items } =
            await this.asyncRequestPaginator.fetchFullList(params);

        return items;
    }

    async getById(id) {
        const { ajax } = this.services;
        const url = `${this.URLS.GET_PREVIEW_TYPES}/${id}`;
        const onError = this.errorHandlers.get;

        const response = await ajax.get({
            url,
            config: { headers: ApplicationJsonHeaders },
            onError,
        });

        return response.data;
    }

    async delteById(id) {
        const {
            services: { ajax },
            URLS,
            errorHandlers,
        } = this;

        const url = URLS.DELETE_PREVIEW_TYPES.replace('{id}', id);
        const onError = errorHandlers.delete;

        const response = await ajax.delete({
            url,
            config: { headers: ApplicationJsonHeaders },
            onError,
        });

        return response.data;
    }

    async getList({ filters = {}, sort = {}, per_page = 20, page = 1 } = {}) {
        const {
            services: { ajax },
            URLS,
            errorHandlers,
        } = this;

        const onError = errorHandlers.get;

        const { data } = await ajax.get({
            url: URLS.GET_PREVIEW_TYPES,
            config: {
                headers: ApplicationJsonHeaders,
                params: {
                    ...mapSortToOrder(sort),
                    ...mapGetListV2Filters(filters),
                    page,
                    per_page,
                },
            },
            onError,
        });

        return { items: data?.results ?? [], itemsTotal: data?.total ?? 0 };
    }
}

export default PreviewTypes;
