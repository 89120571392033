import * as C from './constants';
import * as create from "../../../../reducerConfigurator/actions/create";
import * as edit from "../../../../reducerConfigurator/actions/edit";
import * as fetch from "../../../../reducerConfigurator/actions/fetch";
import * as fetchList from "../../../../reducerConfigurator/actions/fetchList";
import * as remove from "../../../../reducerConfigurator/actions/remove";
import * as fetchListAutocomplete from "../../../../reducerConfigurator/actions/fetchListAutocomplete";
import * as downloadFile from "../../../../reducerConfigurator/actions/downloadFile";

export const actions = {
    create,
    edit,
    fetch,
    fetchList,
    remove,
    fetchListAutocomplete,
    downloadFile,
};

export const actionsConfiguration = [
    {
        constants: C.CREATE_PREVIEW_TYPE,
        actions: actions.create,
    },
    {
        constants: C.FETCH_PREVIEW_TYPES,
        actions: actions.fetchList,
    },
    {
        constants: C.FETCH_PREVIEW_TYPE,
        actions: actions.fetch,
    },
    {
        constants: C.REMOVE_PREVIEW_TYPE,
        actions: actions.remove,
    },
    {
        constants: C.EDIT_PREVIEW_TYPE,
        actions: actions.edit,
    },
];
