import { makeAction, makeRequestActions } from 'reducerConfigurator/configureActions/makeAction';
import * as C from './constants';

export const createPreviewType = makeRequestActions(C.CREATE_PREVIEW_TYPE);
export const fetchPreviewTypes = makeRequestActions(C.FETCH_PREVIEW_TYPES);
export const fetchPreviewType = makeRequestActions(C.FETCH_PREVIEW_TYPE);
export const removePreviewType = makeRequestActions(C.REMOVE_PREVIEW_TYPE);
export const editPreviewType = makeRequestActions(C.EDIT_PREVIEW_TYPE);

export const clearErrors = makeAction(C.CLEAR_ERRORS);
