import { MODULE_NAME } from '../../constants';
import { makeRequestConstant } from 'utils/redux/makeConstants';

export const CREATE_PREVIEW_TYPE = makeRequestConstant(MODULE_NAME + '_CREATE_PREVIEW_TYPE');
export const FETCH_PREVIEW_TYPES = makeRequestConstant(MODULE_NAME + '_FETCH_PREVIEW_TYPES');
export const FETCH_PREVIEW_TYPE = makeRequestConstant(MODULE_NAME + '_FETCH_PREVIEW_TYPE');
export const REMOVE_PREVIEW_TYPE = makeRequestConstant(MODULE_NAME + '_REMOVE_PREVIEW_TYPE');
export const EDIT_PREVIEW_TYPE = makeRequestConstant(MODULE_NAME + '_EDIT_PREVIEW_TYPE');

export const CLEAR_ERRORS = MODULE_NAME + '_CLEAR_ERRORS';
