import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import * as C from './constants';
import * as A from './actions';
import qs from 'qs';
import { getSearch } from 'utils/router/selectors';
import { push } from 'react-router-redux';
import { StockResponse } from '../../models/stockResponse';
import { makeEndPoint } from 'utils/redux/makeEndPoint';
import { selectPreviewTypes } from './selectors';

import { slimServicesFactory } from 'services/servicesFactory';
import errorHandlersConfig from 'services/stock/errorHandlers';

const STOCK_URL = get(window, 'env.STOCK_API_URL', '');
const previewTypes = makeEndPoint(
    STOCK_URL + '/api/v1/preview_types',
    StockResponse,
);

export default function* previewTypesSaga() {
    yield takeLatest(C.FETCH_PREVIEW_TYPES.request, getPreviewTypes);
    yield takeEvery(C.CREATE_PREVIEW_TYPE.request, postPreviewType);
    yield takeEvery(C.EDIT_PREVIEW_TYPE.request, patchPreviewType);
    yield takeEvery(C.FETCH_PREVIEW_TYPE.request, getPreviewType);
    yield takeEvery(C.REMOVE_PREVIEW_TYPE.request, deletePreviewType);
}

function* getPreviewTypes() {
    const slimServices = slimServicesFactory();
    const errorHandlers = errorHandlersConfig(slimServices.alerts);

    const urlParams = qs.parse(yield select(getSearch));
    const params = StockResponse.getListRequestParams({ urlParams });

    yield previewTypes.get({
        action: A.fetchPreviewTypes,
        params: params,
        alerts: {
            failure: true,
        },
        callbacks: {
            failure: errorHandlers.get,
        },
        disableParamsSerializer: true,
    });
}

function* patchPreviewType(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = errorHandlersConfig(slimServices.alerts);

    const previewType = action.payload.item;
    const previewTypeId = previewType.getId();

    yield previewTypes.patch({
        action: A.editPreviewType,
        path: previewTypeId,
        data: previewType.getPreviewTypeForPATCHRequest(),
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'stock:alerts.previewTypeChanged',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            failure: {
                id: previewTypeId,
            },
        },
        callbacks: {
            failure: errorHandlers.patch,
        },
        sagaCallbacks: {
            success: () => push('/stock/previewTypes'),
        },
    });
}

function* postPreviewType(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = errorHandlersConfig(slimServices.alerts);

    const previewType = action.payload.item;
    const handleGoBack = action.payload.goBack;

    yield previewTypes.post({
        action: A.createPreviewType,
        data: previewType.getPreviewTypeForPOSTRequest(),
        alerts: {
            success: true,
            failure: true,
            201: {
                message: 'stock:alerts.previewTypeCreated',
                options: {
                    variant: 'success',
                },
            },
        },
        callbacks: {
            failure: errorHandlers.post,
            success: () => handleGoBack(),
        },
    });
}

function* getPreviewType(action) {
    const slimServices = slimServicesFactory();
    const errorHandlers = errorHandlersConfig(slimServices.alerts);

    const id = action.payload.id;
    const previewTypesById = yield select(selectPreviewTypes);

    if (previewTypesById.get(id) && previewTypesById.get(id).get('id')) {
        yield put(
            A.fetchPreviewType.success({ response: previewTypesById.get(id) }),
        );
    } else {
        yield previewTypes.get({
            action: A.fetchPreviewType,
            path: id,
            alerts: {
                failure: true,
            },
            callbacks: {
                failure: errorHandlers.get,
            },
        });
    }
}

function* deletePreviewType(action) {
    const id = action.payload.id;
    const slimServices = slimServicesFactory();
    const errorHandlers = errorHandlersConfig(slimServices.alerts);

    yield previewTypes.delete({
        action: A.removePreviewType,
        path: id,
        alerts: {
            success: true,
            failure: true,
            200: {
                message: 'stock:alerts.previewTypeDeleted',
                options: {
                    variant: 'success',
                },
            },
        },
        attributes: {
            success: { id },
            failure: { id },
        },
        callbacks: {
            failure: errorHandlers.delete,
        },
    });
}
