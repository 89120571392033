import { FILTER_DEFAULT_VALUES } from 'services/constants';

export const filtersConfig = {
    name: {
        name: 'name',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.name',
        isModalFilter: true,
    },
    code: {
        name: 'code',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.code',
        isModalFilter: true,
    },
    tag: {
        name: 'tag',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.tag',
        isModalFilter: true,
    },
    source_id: {
        name: 'source_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.source',
        isModalFilter: true,
    },
    author_id: {
        name: 'author_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.author',
        isModalFilter: true,
    },
    technology_id: {
        name: 'technology_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.technology',
        isModalFilter: true,
    },
    category_id: {
        name: 'category_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.category',
        isModalFilter: true,
    },
    print_spec_id: {
        name: 'print_spec_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.printSpec',
        isModalFilter: true,
    },
    dedicated_channel_id: {
        name: 'dedicated_channel_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'stock:labels.patternFilters.channel',
        isModalFilter: true,
    },
    techniques: {
        name: 'techniques',
        value: FILTER_DEFAULT_VALUES.MULTI_SELECT_FIELD,
        label: 'stock:labels.patternFilters.techniques',
        isModalFilter: true,
    },
    color_definition: {
        name: 'color_definition',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.color',
        isModalFilter: true,
    },
    overall_size: {
        name: 'overall_size',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.overallSize',
        isModalFilter: true,
    },
    parts: {
        name: 'parts',
        value: FILTER_DEFAULT_VALUES.TEXT_FIELD,
        label: 'stock:labels.patternFilters.parts',
        isModalFilter: true,
    },
    without_source: {
        name: 'without_source',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutSource',
        isModalFilter: true,
    },
    without_color: {
        name: 'without_color',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutColor',
        isModalFilter: true,
    },
    without_category: {
        name: 'without_category',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutCategory',
        isModalFilter: true,
    },
    without_tag: {
        name: 'without_tag',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutTag',
        isModalFilter: true,
    },
    without_previews: {
        name: 'without_previews',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutPreviews',
        isModalFilter: true,
    },
    without_name: {
        name: 'without_name',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutName',
        isModalFilter: true,
    },
    with_color_version: {
        name: 'with_color_version',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withColorVersion',
        isModalFilter: true,
    },
    without_print_spec: {
        name: 'without_print_spec',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.withoutPrintSpec',
        isModalFilter: true,
    },
    is_not_imported: {
        name: 'is_not_imported',
        value: FILTER_DEFAULT_VALUES.SWITCH_FIELD,
        label: 'stock:labels.patternFilters.isNotImported',
        invertedSwitchKey: 'is_imported',
        isModalFilter: true,
    },
    created_at_before: {
        name: 'created_at_before',
        value: FILTER_DEFAULT_VALUES.DATE_PICKER_FIELD,
        label: 'boxes:labels.createdAtBefore',
        labelAlt: 'boxes:labels.createdAt',
        isModalFilter: true,
        order: 1,
    },
    created_at_after: {
        name: 'created_at_after',
        value: FILTER_DEFAULT_VALUES.DATE_PICKER_FIELD,
        label: 'boxes:labels.createdAtAfter',
        labelAlt: 'boxes:labels.createdAt',
        isModalFilter: true,
        order: 0,
    },
    external_producer_id: {
        name: 'external_producer_id',
        value: FILTER_DEFAULT_VALUES.AUTOCOMPLETE_FIELD,
        label: 'arm:labels.externalProducerName',
        isModalFilter: false,
    },
};
