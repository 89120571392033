import * as Yup from 'yup';
import moment from 'moment';
import { ItemModel } from 'reducerConfigurator/itemModel/itemModel';

const getPreviewTypeSchema = (t = (key) => key) => {
    const T_REQUIRED = t('app:validation.required');
    const T_MAX_LENGTH = (max) => t('app:validation.maxLength', { max });

    return Yup.object().shape({
        id: Yup.string()
            .ensure()
            .trim(),
        code: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        file_name_pattern: Yup.string()
            .ensure()
            .trim()
            .when('mainPreview', {
                is: false,
                then: Yup.string()
                    .required(T_REQUIRED)
                    .max(255, T_MAX_LENGTH(255)),
            }),
        height: Yup.number()
            .required(T_REQUIRED)
            .max(10000, t('app:validation.maxHeight', { max: 10000 }))
            .positive(t('app:validation.positiveHeight')),
        width: Yup.number()
            .required(T_REQUIRED)
            .max(10000, t('app:validation.maxWidth', { max: 10000 }))
            .positive(t('app:validation.positiveWidth')),
        fileType: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        channel: Yup.string()
            .required(T_REQUIRED)
            .ensure()
            .trim()
            .max(255, T_MAX_LENGTH(255)),
        technology: Yup.object()
            .shape({
                id: Yup.string()
                    .required(T_REQUIRED),
            })
            .required(T_REQUIRED),
        thumbnailId: Yup.string(),
        mainPreview: Yup.boolean(),
    });
};

export class PreviewType extends ItemModel {
    constructor(props = {}) {
        super(props);

        this.id = props.id || undefined;
        this.code = props.code || '';
        this.technology = props.technology || {};
        this.file_name_pattern = props.file_name_pattern || '';
        this.height = props.height || '';
        this.width = props.width || '';
        this.thumbnail_file = props.thumbnail_file || props.thumbnailFile || {};
        this.fileType = props.file_type || props.fileType || '';
        this.channel = props.channel || '';
        this.createdAt = props.created_at || props.createdAt || '';
        this.description = props.description || '';
        this.mainPreview = props.mainPreview
            ? props.mainPreview
            : props.file_name_pattern
                ? !props.file_name_pattern
                : true;

        if (props.thumbnailId) this.thumbnail_file.id = props.thumbnailId;
    }

    static getSchema = getPreviewTypeSchema;

    static getSalesChannelsOptions = (t) => ([
        {
            label: t('stock:labels.salesChannelsOptions.decomonkey'),
            value: 'decomonkey',
        },
        {
            label: t('stock:labels.salesChannelsOptions.artgeist'),
            value: 'artgeist',
        },
        {
            label: t('stock:labels.salesChannelsOptions.bimago'),
            value: 'bimago',
        },
        {
            label: 'Dropshipping',
            value: 'dropshipping',
        }
    ]);

    getId = () => this.id && this.id.toString();
    getCreatedAt = () => moment(this.createdAt).format('DD/MM/YYYY');
    getType = () => this.type;
    getCode = () => this.code;
    getTechnology = () => this.technology;
    getTechnologyName = () => {
        const technologyCode = this.technology?.code ? `[${this.technology.code}]` : '';

        return `${this.technology.name} ${technologyCode}`;
    };
    getSuffix = () => this.file_name_pattern;
    getHeight = () => this.height;
    getWidth = () => this.width;
    getFileType = () => this.fileType;
    getChannel = () => this.channel;
    getDescription = () => this.description;

    updatePreviewType = (newData = {}) => {
        const updateField = this.updateFieldFromNewData(newData);

        updateField('code');
        updateField('file_name_pattern');
        updateField('height');
        updateField('width');
        updateField('fileType');
        updateField('channel');
        updateField('description');
        updateField('mainPreview');

        if (newData.technology && newData.technology.id !== this.technology.id) {
            this.technology.id = newData.technology.id;
            this.updatedFields.push('technology');
        }
        if (newData.thumbnailId !== this.thumbnail_file.id) {
            this.thumbnail_file.id = newData.thumbnailId;
            this.updatedFields.push('thumbnail_file');
        }

        return this;
    };

    getPreviewTypeForPOSTRequest = () => {
        const previewTypeObject = {};

        if (this.code) previewTypeObject.code = this.code;
        if (this.height) previewTypeObject.height = parseInt(this.height);
        if (this.width) previewTypeObject.width = parseInt(this.width);
        if (this.fileType) previewTypeObject.fileType = this.fileType;
        if (this.channel) previewTypeObject.channel = this.channel;
        if (this.description) previewTypeObject.description = this.description;
        if (this.technology.id) previewTypeObject.technology = { id: this.technology.id };
        if (this.thumbnail_file.id) previewTypeObject.thumbnail_file = { id: this.thumbnail_file.id };

        if (this.mainPreview) {
            previewTypeObject.file_name_pattern = '';
        } else {
            if (this.file_name_pattern) previewTypeObject.file_name_pattern = this.file_name_pattern;
        }

        return previewTypeObject;
    };

    getPreviewTypeForPATCHRequest = () => {
        const previewTypeObject = {};

        if (this.updatedFields.includes('code')) previewTypeObject.code = this.code;
        if (this.updatedFields.includes('height')) previewTypeObject.height = parseInt(this.height);
        if (this.updatedFields.includes('width')) previewTypeObject.width = parseInt(this.width);
        if (this.updatedFields.includes('fileType')) previewTypeObject.fileType = this.fileType;
        if (this.updatedFields.includes('channel')) previewTypeObject.channel = this.channel;
        if (this.updatedFields.includes('description')) previewTypeObject.description = this.description;
        if (this.updatedFields.includes('technology')) previewTypeObject.technology = this.technology;
        if (this.updatedFields.includes('thumbnail_file')) previewTypeObject.thumbnail_file = this.thumbnail_file.id;

        if (this.mainPreview) {
            previewTypeObject.file_name_pattern = '';
        } else {
            if (this.updatedFields.includes('file_name_pattern')) previewTypeObject.file_name_pattern = this.file_name_pattern;
        }

        return previewTypeObject;
    };

    getPreviewTypePlainObject = (create) => ({
        code: this.code,
        file_name_pattern: this.file_name_pattern,
        height: this.height,
        width: this.width,
        technology: this.technology,
        fileType: this.fileType,
        channel: this.channel,
        description: this.description,
        thumbnail_file: this.thumbnail_file,
        thumbnailId: this.thumbnail_file.id,
        mainPreview: create ? false : this.mainPreview,
    });
}
